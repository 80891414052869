import React, { Component } from 'react';
import { fbApp } from 'datastore/firebase'
import { getDatabase, ref, child, get, update, set, onValue } from "firebase/database";
import Form from 'components/forms';
import Modal from 'react-modal';
import InvoiceForm from 'components/forms/invoiceform'
import { getAuth, signOut } from 'firebase/auth';

Modal.setAppElement('#root');

export class FileMenu extends Component {
  constructor(props){
    super(props);
    this.state = {
      showModal: false,
      showInvoiceModal: false,
      showFileMenu: false,
      showFileSubMenu: false,
      formName: null,
    }
  }
  
  componentDidMount() {
    
  }
  
  
  handleOpenModal = ( formName ) => {
    if(formName != null) {
      this.setState({ showModal: true, formName: formName, showInvoiceModal: false, showFileMenu: false, showFileSubMenu: false });
    } else {
      this.setState({ showModal: true, showInvoiceModal: false, showFileMenu: false, showFileSubMenu: false});
    }
  }
  
  handleCloseModal = () => {
    this.setState({ showModal: false, formName: null, showInvoiceModal: false });
  }
  
  handleInvoiceModal = () => {
    var showInvoiceModal = !this.state.showInvoiceModal;
    this.setState({ showModal: false, formName: null, showInvoiceModal: showInvoiceModal })
  }
  
  toggleFileMenu = () => {
    var showFileMenu = !this.state.showFileMenu;
    this.setState({ showFileMenu: showFileMenu })
  }
  
  handleFileMenuOpen = () => {
    this.setState({ showFileMenu: true })
  }

  handleFileMenuClose = () => {
    this.setState({ showFileMenu: false })
    this.handleFileSubMenuClose()
  }
  
  handleFileSubMenuClose = () => {
    this.setState({ showFileSubMenu: false })
  }
  
  handleFileSubMenuOpen = () => {
    this.setState({ showFileSubMenu: true })
  }
  
  toggleFileSubMenu = () => {
    var showFileSubMenu = !this.state.showFileSubMenu;
    this.setState({showFileSubMenu : showFileSubMenu})
  }
  
  renderFileMenu = () => {
    return (
    <div className="menu-navbar" role={"menuitem"} style={{'whiteSpace': 'nowrap', 'background': 'gray', 'position': 'absolute',top: '0px', left: '100%', flexDirection: 'column', width: 'calc(100% + 10vw)', textAlign: 'right', zIndex: 1}}>
        <div style={{width: '100%'}} tabindex={0} role={"button"} ariaPressed={false} className="menu-item-container" onKeyPress={() => this.handleOpenModal("sales_agent")} onClick={() => this.handleOpenModal("sales_agent")}>
          <p>Sales Agent</p>
        </div>
        <div style={{width: '100%'}} tabindex={0} role={"button"} ariaPressed={false} className="menu-item-container" onKeyPress={() => this.handleOpenModal("region_code")} onClick={() => this.handleOpenModal("region_code")}>
          <p>Region Code</p>
        </div>
        <div style={{width: '100%'}} tabindex={0} role={"button"} ariaPressed={false} className="menu-item-container" onKeyPress={() => this.handleOpenModal("customer_type")} onClick={() => this.handleOpenModal("customer_type")}>
          <p>Customer Type</p>
        </div>
        <div style={{width: '100%'}} tabindex={0} role={"button"} ariaPressed={false} className="menu-item-container" onKeyPress={() => this.handleOpenModal("job_type")} onClick={() => this.handleOpenModal("job_type")}>
          <p>Job Type</p>
        </div>
        <div style={{width: '100%'}} tabindex={0} role={"button"} ariaPressed={false} className="menu-item-container" onKeyPress={() => this.handleOpenModal("field_tech")} onClick={() => this.handleOpenModal("field_tech")}>
          <p>Field Tech</p>
        </div>
        <div style={{width: '100%'}} tabindex={0} role={"button"} onBlur={this.handleFileMenuClose} ariaPressed={false} className="menu-item-container" onKeyPress={() => this.handleOpenModal("invoice_item")} onClick={() => this.handleOpenModal("invoice_item")}>
          <p>Invoice Item</p>
        </div>
      </div>
    )
  }

  signOut = () => {
    const auth = getAuth();
    signOut(auth)
    this.handleFileMenuClose()
  }


  render() {
    return (
      <div role={"none"}>
        <div role={"menubar"} className="menu-navbar" style={{position: 'relative', left: '0px'}}>
          <div tabindex={0} role={"menu"} ariaPressed={false} className="menu-item-container" onFocus={this.handleFileMenuOpen} onClick={() => this.toggleFileMenu()}>
            <p>File</p>
          </div>
          {this.state.showFileMenu == true ? (
          <div style={{ zIndex: 5, display: 'inline-flex', flexDirection: 'column', position: 'absolute', left: '0px', top:'2rem', background: 'black', color: 'white'}} role={"none"} ariaPressed={false} className="menu-item-container">
            <div onFocus={this.handleFileSubMenuOpen} className={"menu-item-container"} tabindex={0} role={"menu"} style={{ display: 'inline-flex', flexDirection: 'row', position: 'relative',margin: '1rem', textAlign: 'left', zIndex: 3, width: '90%'}} onClick={() => this.handleFileSubMenuOpen()}>
              <p style={{border: '0px', margin: '0px', padding: '0px'}}>New</p>
              <p style={{border: '0px'}}>{'>'}</p>
            </div>
            <div className={"menu-item-container"} tabindex={0} role={"menu"} style={{ display: 'inline-flex', flexDirection: 'row', position: 'relative',margin: '1rem', textAlign: 'left', zIndex: 2, width: '90%'}} onKeyPress={(e) => { return e.key = 'Enter' ? this.signOut() : null }} onClick={() => this.signOut()}>
              <p style={{border: '0px', margin: '0px', padding: '0px'}}>Logout</p>
              <p style={{border: '0px'}}>{'>'}</p>
            </div>
            <div style={{width: '100vw', height: '100vh', position: 'absolute', display: 'block', top: '-2rem', zIndex: 1}} onClick={() => this.handleFileMenuClose()}></div>
            {this.state.showFileSubMenu == true ? this.renderFileMenu() : null}
          </div>
          ) : null}
        </div>
        
        <Modal
           isOpen={this.state.showModal}
           contentLabel={`${this.state.formName} modal`}
        >
          <button onClick={this.handleCloseModal}>X</button>
          <Form formName={this.state.formName} onSubmit={this.handleCloseModal} />
        </Modal>
        
        <Modal
           isOpen={this.state.showInvoiceModal}
           contentLabel={`Invoice modal`}
        >
          <button onClick={this.handleInvoiceModal}>X</button>
          <InvoiceForm onSubmit={this.handleCloseModal} />
        </Modal>
      </div>
    )
  }

}