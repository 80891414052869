//const ENDPOINT_HOSTNAME = "10.0.10.85:8080";
//const GANTT_CHART_CALL_DATA_ENDPOINT = `http://${ENDPOINT_HOSTNAME}/v1/metrics/calls/gantt`;
const ENDPOINT_HOSTNAME = "api-v2.brhapps.com";
//const GANTT_CHART_EMAIL_DATA_ENDPOINT = `https://${ENDPOINT_HOSTNAME}/v1/metrics/received_emails/gantt`;

const testSchema: RJSFSchema = {
  title: "Todo",
  type: "object",
  required: ["title"],
  properties: {
    title: {type: "string", title: "Title", default: "A new task"},
    done: {type: "boolean", title: "Done?", default: false}
  }
};

const invoiceSchema: RJSFSchema = {
  title: "New invoice",
  type: "object",
  required: ["item", "rate"],
  properties: {
    item: {type: "string", title: "Item Code", default: "ITM"},
    quantity: {type: "string", title: "Quantity", default: ""},
    description: {type: "string", title: "Description", default: ""},
    uom: {type: "string", title: "Unit of measure", default: ""},
    rate: { type: "string", title: "Rate", default: "" }
  }
};

const salesAgentSchema: RJSFSchema = {
  title: "New Sales Agent",
  type: "object",
  required: ["name", "code"],
  properties: {
    name: {type: "string", title: "Salesman name", default: ""},
    code: {type: "string", title: "Code", default: ""}
  }
};

const regionSchema: RJSFSchema = {
  title: "New region",
  type: "object",
  required: ["region", "code"],
  properties: {
    region: {type: "string", title: "Region name", default: ""},
    code: {type: "string", title: "Code", default: ""}
  }
};

const customerTypeSchema: RJSFSchema = {
  title: "New customer type",
  type: "object",
  required: ["customer_type", "code"],
  properties: {
    customer_type: {type: "string", title: "Customer Type", default: ""},
    code: {type: "string", title: "Code", default: ""}
  }
};

const jobTypeSchema: RJSFSchema = {
  title: "New job type",
  type: "object",
  required: ["job_type", "code"],
  properties: {
    job_type: {type: "string", title: "Job Type", default: ""},
    code: {type: "string", title: "Code", default: ""}
  }
};

const fieldTechSchema: RJSFSchema = {
  title: "New field tech",
  type: "object",
  required: ["tech_name", "code"],
  properties: {
    tech_name: {type: "string", title: "Tech name", default: ""},
    code: {type: "string", title: "Code", default: ""}
  }
};

const invoiceItemSchema: RJSFSchema = {
  title: "New invoice item",
  type: "object",
  required: ["description", "code"],
  properties: {
    description: {type: "string", title: "Item Description", default: ""},
    code: {type: "string", title: "Code", default: ""}
  }
};


module.exports = {
  "ENDPOINT_HOSTNAME": ENDPOINT_HOSTNAME,
  "testSchema": testSchema,
  "invoiceSchema": invoiceSchema,
  "salesAgentSchema": salesAgentSchema,
  "regionSchema": regionSchema,
  "customerTypeSchema": customerTypeSchema,
  "jobTypeSchema": jobTypeSchema,
  "fieldTechSchema": fieldTechSchema,
  "invoiceItemSchema": invoiceItemSchema
}
