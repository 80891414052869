import React, { Component } from 'react';
import { fbApp } from '../../datastore/firebase'
import { getDatabase, ref, child, get, update, set, onValue } from "firebase/database";

export class FieldTechTable extends Component {
  constructor(props){
    super(props);
    this.state = {
      techList: [],
      editMode: false,
      editId: null
    }
  }
  
  fetchData = () => {
    const dbRef = ref(getDatabase(fbApp));
    var techRef = child(dbRef, `accounting_app/field_tech`)
    onValue( techRef, (snapshot) => {
      if(snapshot.exists()) {
        var techList = []
        snapshot.forEach((childSnapshot) => {
          const childKey = childSnapshot.key;
          const childVal = childSnapshot.val();
          console.log(childKey, childVal)
          techList.push({...childVal, id: childKey});
        });
        
        if(this.state.editId != null) {
          this.setState({techList: techList})
        } else {
          this.setState({techList: techList, editMode: false, editId: null})
        }
      } else {
        console.log("No data available")
        this.setState({techList: [], editMode: false, editId: null})
      }
    })

  }
  
  componentDidMount() {
    console.log("componentDidMount()")
    this.fetchData();
  }
  
  editTech = (techId) => {
    var editModeCurrent = this.state.editMode;
    var editIdCurrent = this.state.editId;
    console.log("editTech", techId, editIdCurrent, editModeCurrent )
    var newList = this.state.techList;
    var listOfId = newList.map(x => x.id);
    var listIndx = listOfId.indexOf(techId);
    var refLocation = `accounting_app/field_tech/${techId}`
    
    if(editIdCurrent == techId) {
      this.setState({editMode: !editModeCurrent, editId: null})
    } else if( editIdCurrent != techId && editModeCurrent == true){
      this.setState({editId: editIdCurrent == null ?  null : techId})
      listIndx = listOfId.indexOf(editIdCurrent)
      refLocation = `accounting_app/field_tech/${editIdCurrent}`
    } else {
      this.setState({editMode: !editModeCurrent, editId: editIdCurrent == null ? techId : null})
    }
    
    const dbRef = ref(getDatabase(fbApp))
    return set(ref(getDatabase(fbApp), refLocation), newList[listIndx])
  }
  
  updateDatabase = ( valueType, value, techId ) => {
    console.log("update_db", `accounting_app/field_tech/${techId}/${valueType}`, value)
    const dbRef = ref(getDatabase(fbApp));
    return set(ref(getDatabase(fbApp), `accounting_app/field_tech/${techId}/${valueType}`), value)
  }
  
  updateTech = ( valueType, e, techId ) => {
    const value = e.target.value
    console.log("updateTech", valueType, value, techId)
    var newList = this.state.techList;
    var listOfId = newList.map(x => x.id);
    var listIndx = listOfId.indexOf(techId)
    newList[listIndx][valueType] = value;
    this.setState({techList: newList})
  }
  
  deleteTech = (techId) => {
    if(this.state.editId == techId) {
      this.setState({editId: null, editMode: false})
    }
    return set( ref(getDatabase(fbApp), `accounting_app/field_tech/${techId}`), null )
  }
  
  renderTechList = () => {
    var techList = this.state.techList;
    return techList.length > 0 ? (
        <table className="table-fill">
          <thead>
            <tr>
              <th>Tech name</th>
              <th>Code</th>
              <th></th>
              <th></th>
            </tr>
          </thead>
          <tbody>
          { techList.map(x => {
              return (
                <tr>
                  { (this.state.editMode == true && this.state.editId == x.id ) ? ( <td><input onChange={(e) => this.updateTech("tech_name", e, x.id)} value={x.tech_name} /></td>  ) : ( <td>{x.tech_name}</td> ) }
                  { (this.state.editMode == true && this.state.editId == x.id ) ? ( <td><input onChange={(e) => this.updateTech("code", e, x.id)} value={x.code} /></td>  ) : ( <td>{x.code}</td> ) }
                  <td style={{cursor: 'pointer', width: '64px', height: '64px', textAlign: 'right'}} onClick={() => this.editTech(x.id)}>{(this.state.editMode == true && this.state.editId == x.id ) ? 'SAVE' : 'EDIT' }</td>
                  <td style={{cursor: 'pointer', width: '64px', height: '64px', textAlign: 'right'}} onClick={() => this.deleteTech(x.id)}>DELETE</td>
                </tr>
              )
            })
          }
          </tbody>
        </table>
      ) : (
      <div>
        <p>N/A</p>
      </div>
    )
  }
  
  render() {
    return this.renderTechList();
  }

}