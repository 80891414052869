import React, { Component } from 'react';
import Form from "@rjsf/core";
import validator from "@rjsf/validator-ajv8";
import { RJSFSchema } from "@rjsf/utils";
import { fbApp } from '../../datastore/firebase'
import { getDatabase, ref, child, get, update, push } from "firebase/database";
//import { getAuth } from "firebase/auth";
import {
  testSchema,
  invoiceSchema,
  salesAgentSchema,
  regionSchema,
  customerTypeSchema,
  jobTypeSchema,
  fieldTechSchema,
  invoiceItemSchema
} from '../../constants';

const log = (type) => console.log.bind(console, type);
const dbRef = ref(getDatabase(fbApp));
class FormApp extends Component {
  constructor(props){
    super(props);
    this.state = {
      formData: null
    }
  }
/*
  componentDidMount() {
    console.log("componentDidMount()")

    const dbRef = ref(getDatabase(fbApp));
    var techRef = child(dbRef, `accounting_app/techs`)
    get( techRef ).then( (snapshot) => {
      if(snapshot.exists()) {
        var techList = []
        snapshot.forEach((childSnapshot) => {
          const childKey = childSnapshot.key;
          const childVal = childSnapshot.val();
          console.log(childKey, childVal)
          techList.push(childVal);
        });
        this.setState({techList: techList})
      } else {
        console.log("No data available")
      }
    })
  }
*/

  submitRequest = ( formData, formName ) => {
    console.log("submitRequest - firebase", formData, formName)
    var techRef = child(dbRef, `accounting_app/${formName}`)
    var newSnapKey = push( techRef ).key;
    formData.id = newSnapKey;
    return update(child(techRef, `${newSnapKey}`), formData)
    /*get( techRef ).then( (snapshot) => {
      if(!snapshot.exists()) {
        console.log("No data available")
      }
    })*/
  }

  onSubmit = ( eventData ) => {
    var formData = eventData.formData;
    var formDataKeys = Object.keys(formData)
    for(let i = 0; i < formDataKeys.length; i++) {
      if(formData[formDataKeys[i]] == undefined || formData[formDataKeys[i]] == null) {
        formData[formDataKeys[i]] = "";
      }
    }
    
    var formName = this.props.formName;
    console.log("eventData.formData", eventData.formData)
    this.setState({ formData: formData }, () => {
      if(this.props.onSubmit != null) {
        this.submitRequest(formData, formName);
        this.props.onSubmit();
      }
    })
  }
  
  getSchema = () => {
    console.log("getSchema", this.props.formName)
    var formName = this.props.formName;
    var selectedSchema = null;
    switch(formName) {
      case "invoice":
        selectedSchema = invoiceSchema;
        break;
      case "sales_agent":
        selectedSchema = salesAgentSchema;
        break;
      case "region_code":
        selectedSchema = regionSchema;
        break;
      case "customer_type":
        selectedSchema = customerTypeSchema;
        break;
      case "job_type":
        selectedSchema = jobTypeSchema;
        break;
      case "field_tech":
        selectedSchema = fieldTechSchema;
        break;
      case "invoice_item":
        selectedSchema = invoiceItemSchema;
        break;
      default:
        selectedSchema = testSchema;
        break;
    }
    
    return selectedSchema;
  }

  render() {
    return this.props.formName != null ? (
      <div>
        <h1>New {this.props.formName}</h1>
        <Form
          schema={this.getSchema()}
          validator={validator}
          onSubmit={this.props.onSubmit != null ? this.onSubmit : log("submitted")}
        />
      </div>
    ) : null
  }
}

/*
  onError={onError != null ? onError : log("errors")}
  onChange={onChange != null ? onChange : log("changed")}
*/

export default FormApp;
