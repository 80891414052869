import { initializeApp } from 'firebase/app';

const config = {
    apiKey: "AIzaSyAR6z_Y-f9O9ORBcOc9RRYO2VDDjnXfnks",
    authDomain: "jigsaw-production-1.firebaseapp.com",
    databaseURL: "https://jigsaw-production-1.firebaseio.com",
    projectId: "jigsaw-production-1",
    storageBucket: "jigsaw-production-1.appspot.com",
    messagingSenderId: "1072838179851"
}

export const fbApp = initializeApp(config);