import React, { Component } from 'react';
import { fbApp } from 'datastore/firebase'
import { getDatabase, ref, child, get, update, set, onValue } from "firebase/database";

export class FirebaseTable extends Component {
  constructor(props){
    super(props);
    this.state = {
      fireList: [],
      editMode: false,
      editId: null,
      listName: props.listName || "unknown",
      listHeaders: props.listHeaders || []
    }
  }
  
  fetchData = () => {
    const dbRef = ref(getDatabase(fbApp));
    var techRef = child(dbRef, `accounting_app/${this.state.listName}`)
    onValue( techRef, (snapshot) => {
      if(snapshot.exists()) {
        var fireList = []
        snapshot.forEach((childSnapshot) => {
          const childKey = childSnapshot.key;
          const childVal = childSnapshot.val();
          console.log(childKey, childVal)
          fireList.push({...childVal, id: childKey});
        });
        
        if(this.state.editId != null) {
          this.setState({fireList: fireList})
        } else {
          this.setState({fireList: fireList, editMode: false, editId: null})
        }
      } else {
        console.log("No data available")
        this.setState({fireList: [], editMode: false, editId: null})
      }
    })

  }
  
  componentDidMount() {
    console.log("componentDidMount()")
    this.fetchData();
  }
  
  editRow = (rowId) => {
    var editModeCurrent = this.state.editMode;
    var editIdCurrent = this.state.editId;
    console.log("editRow", rowId, editIdCurrent, editModeCurrent )
    var newList = this.state.fireList;
    var listOfId = newList.map(x => x.id);
    var listIndx = listOfId.indexOf(rowId);
    var refLocation = `accounting_app/${this.state.listName}/${rowId}`
    
    if(editIdCurrent == rowId) {
      this.setState({editMode: !editModeCurrent, editId: null})
    } else if( editIdCurrent != rowId && editModeCurrent == true){
      this.setState({editId: editIdCurrent == null ?  null : rowId})
      listIndx = listOfId.indexOf(editIdCurrent)
      refLocation = `accounting_app/${this.state.listName}/${editIdCurrent}`
    } else {
      this.setState({editMode: !editModeCurrent, editId: editIdCurrent == null ? rowId : null})
    }
    
    const dbRef = ref(getDatabase(fbApp))
    return set(ref(getDatabase(fbApp), refLocation), newList[listIndx])
  }
  
  updateDatabase = ( rowName, value, rowId ) => {
    console.log("update_db", `accounting_app/${this.state.listName}/${rowId}/${rowName}`, value)
    const dbRef = ref(getDatabase(fbApp));
    return set(ref(getDatabase(fbApp), `accounting_app/${this.state.listName}/${rowId}/${rowName}`), value)
  }
  
  updateRow = ( rowName, e, rowId ) => {
    const value = e.target.value
    console.log("updateRow", rowName, value, rowId)
    var newList = this.state.fireList;
    var listOfId = newList.map(x => x.id);
    var listIndx = listOfId.indexOf(rowId)
    newList[listIndx][rowName] = value;
    this.setState({fireList: newList})
  }
  
  deleteRow = (rowId) => {
    if(this.state.editId == rowId) {
      this.setState({editId: null, editMode: false})
    }
    return set( ref(getDatabase(fbApp), `accounting_app/${this.state.listName}/${rowId}`), null )
  }
  
  calculateRow = (x, header) => {
    var calculation = header.calculation;
    let dataToStore = {[header.value] : 0};
    switch(calculation.action) {
      case 'multiply':
        var curVal = 1;
        //Process calculation
        for(let j = 0; j < calculation.fields.length; j++) {
          console.log(`[${calculation.fields[j]}]`,[calculation.fields[j]])

          var field = x[calculation.fields[j]];
          console.log(`${calculation.fields[j]} '${field}' ${typeof(field)}`)
          if(field != null && field != "") {
            /*if(calculation.fields[j] === 'billed') {
              field = moment.duration(field, 'seconds').asHours()
            }*/

            curVal = parseFloat(curVal * field);
            //field = 0;
          } 
        }
        dataToStore[header.value] = curVal;
        break;
      default:
        break;
    }
/*
      

      console.log("[JobSummaryBody] dataToStore[data.field]", `${dataToStore[data.field]}`, `${data.field}`)
      
  
  var newData = Object.assign(laborData[k], dataToStore);
  laborData[k] = newData;
  this.setState({'labor': newState})
*/
    return (
      <td>{header.calculation.type == 'dollar' ? '$' : ''}{dataToStore[header.value]}</td>
    )
  }
  
  renderRow = (x, header) => {
    return (
      <td>{x[header.value]}</td>
    )
  }
  
  renderFirebaseList = () => {
    var fireList = this.state.fireList;
    return fireList.length > 0 ? (
        <table className="table-fill">
          <thead>
            <tr>
              {this.state.listHeaders.map(x => {
                return <th>{x.key}</th>;
              })}
              <th></th>
              <th></th>
            </tr>
          </thead>
          <tbody>
          { fireList.map(x => {
              return (
                <tr>
                  {
                    this.state.listHeaders.map(header => {
                      //return <td>{header.value}</td>;
                      return (this.state.editMode == true && this.state.editId == x.id ) ? (
                        <td>
                          {
                            header.type == "calculated" ? this.calculateRow(x, header) : ( <input onChange={(e) => this.updateRow(header.value, e, x.id)} value={x[header.value]} /> )
                          }
                        </td>
                      ) : ( header.type == "calculated" ? this.calculateRow(x, header) : this.renderRow(x, header) )
                    })
                  }
                  <td style={{cursor: 'pointer', width: 'auto', height: '64px', textAlign: 'right'}} onClick={() => this.editRow(x.id)}>{(this.state.editMode == true && this.state.editId == x.id ) ? 'SAVE' : 'EDIT' }</td>
                  <td style={{cursor: 'pointer', width: 'auto', height: '64px', textAlign: 'right'}} onClick={() => this.deleteRow(x.id)}>DELETE</td>
                </tr>
              )
            })
          }
          </tbody>
        </table>
      ) : (
      <div>
        <p>N/A</p>
      </div>
    )
  }
  
  render() {
    return this.renderFirebaseList();
  }

}