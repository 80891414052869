import React, { Component } from 'react';
import Form from "@rjsf/core";
import validator from "@rjsf/validator-ajv8";
import { RJSFSchema } from "@rjsf/utils";
import { fbApp } from 'datastore/firebase'
import { getDatabase, ref, child, get, update, push } from "firebase/database";
//import { getAuth } from "firebase/auth";
import { invoiceSchema } from 'constants';
const log = (type) => console.log.bind(console, type);
const dbRef = ref(getDatabase(fbApp));
class InvoiceForm extends Component {
  constructor(props){
    super(props);
    this.state = {
      formData: null
    }
  }
/*
  componentDidMount() {
    console.log("componentDidMount()")

    const dbRef = ref(getDatabase(fbApp));
    var techRef = child(dbRef, `accounting_app/techs`)
    get( techRef ).then( (snapshot) => {
      if(snapshot.exists()) {
        var techList = []
        snapshot.forEach((childSnapshot) => {
          const childKey = childSnapshot.key;
          const childVal = childSnapshot.val();
          console.log(childKey, childVal)
          techList.push(childVal);
        });
        this.setState({techList: techList})
      } else {
        console.log("No data available")
      }
    })
  }
*/

  submitRequest = ( formData, formName ) => {
    console.log("submitRequest - firebase", formData, formName)
    var techRef = child(dbRef, `accounting_app/${formName}`)
    var newSnapKey = push( techRef ).key;
    formData.id = newSnapKey;
    return update(child(techRef, `${newSnapKey}`), formData)
    /*get( techRef ).then( (snapshot) => {
      if(!snapshot.exists()) {
        console.log("No data available")
      }
    })*/
  }

  onSubmit = ( eventData ) => {
    var formData = eventData.formData;
    var formDataKeys = Object.keys(formData)
    for(let i = 0; i < formDataKeys.length; i++) {
      if(formData[formDataKeys[i]] == undefined || formData[formDataKeys[i]] == null) {
        formData[formDataKeys[i]] = "";
      }
    }
    
    var formName = "invoice";
    console.log("eventData.formData", eventData.formData)
    this.setState({ formData: formData }, () => {
      if(this.props.onSubmit != null) {
        this.submitRequest(formData, formName);
        this.props.onSubmit();
      }
    })
  }

  render() {
    return this.props.onSubmit != null ? (
      <div>
        <h1>New Invoice</h1>
        <Form
          schema={invoiceSchema}
          validator={validator}
          onSubmit={this.props.onSubmit != null ? this.onSubmit : log("submitted")}
        />
      </div>
    ) : null
  }
}

/*
  onError={onError != null ? onError : log("errors")}
  onChange={onChange != null ? onChange : log("changed")}
*/

export default InvoiceForm;
