import './App.css';
import './login.css';
import React, { Component } from 'react';
import { InvoiceItemTbl, FieldTechTbl, CustomerTypeTbl, RegionCodeTbl, SalesAgentTbl, InvoiceTbl } from 'components/tables';
import { getAuth, signInWithEmailAndPassword, onAuthStateChanged } from 'firebase/auth';
import { FileMenu } from 'components/menus'
import Modal from 'react-modal';
/*
import { getStorage } from 'firebase/storage';
*/


class App extends Component {
  constructor(props){
    super(props);
    this.state = {
      showLoginModal: false,
      authUser: null,
      login : {
        email: '',
        password: '',
      },
      error: {
        code: null,
        message: null
      },
      showPass: false
    }
    this.listener = null;
  }
  
  componentDidMount() {
    Modal.setAppElement('#main');

    const auth = getAuth();
    const myUser = auth.currentUser;
    if(auth.currentUser != null) {
      console.log("auth.currentUser", auth.currentUser)
      this.setState({authUser: auth.currentUser})
    } else {
      console.log("Not signed in")
      this.setState({authUser: null})
    }
    
    this.listener = onAuthStateChanged(auth, async (user) => { console.log(user); this.setState({authUser: user}) });
    
  }
  
  componentWillUnmount() {
    this.listener()
  }
  
  closeLoginModal = () => {
    this.setState({showLoginModal: false})
  }
  
  openLoginModal = () => {
    this.setState({showLoginModal: true})
  }
  
  toggleLoginModal = () => {
    var showLoginModal = !this.state.showLoginModal;
    this.setState({showLoginModal: showLoginModal})
  }
  
  signIn = () => {
    const auth = getAuth();
    signInWithEmailAndPassword(auth, this.state.login.email, this.state.login.password)
    .then(function(currentUser){
      console.log("currentUser", currentUser)
      this.setState({authUser: currentUser, showLoginModal: false})
    }.bind(this))
    .catch(function(error){
      var errorCode = error.code;
      var errorMessage = error.message;
      this.setState({
        'error': {
          code: errorCode,
          message: errorMessage
        }
      })
    })
  }
  
  updateLoginState = (event) => {
    var name = event.target.name;
    var val = event.target.value;
    var curState = this.state.login;
    switch(name) {
      case 'email':
        curState.email = val;
        this.setState({ login : curState });
        break;
      case 'password':
        curState.password = val;
        this.setState({ login : curState });
        break;
      default:
        break
    }
  }
  
  friendlyError(code) {
    if(code === null)
      return;
    
    switch(code) {
      case 'auth/user-not-found':
        return 'Account not found, register with a developer!';
      case 'auth/wrong-password':
        return 'Invalid password!';
      case 'auth/internal-error':
        return 'Google is having issues right now, refresh this page or redo your last action :('
      default:
        return code;
    }
  }
  
  render() {
    var friendlyErrorMessage = this.friendlyError(this.state.error.code);
    var errorMessage = {
      'email': this.state.error.code !== 'auth/wrong-password' ? friendlyErrorMessage : null,
      'password': this.state.error.code === 'auth/wrong-password' ? friendlyErrorMessage : null
    };
    
    var inputType = {
      'password': this.state.showPass === true ? 'text': 'password'
    }
    return (
      <div className="App" id="main">
        {this.state.authUser != null ? ( <FileMenu /> ) : (
          <div role={"menubar"} className="menu-navbar" style={{position: 'relative', left: '0px'}}>
            <div tabindex={0} role={"menu"} ariaPressed={false} className="menu-item-container" onClick={() => this.toggleLoginModal()}>
              <p>Login</p>
            </div>
          </div>
        )}
        
        {this.state.authUser != null ? (
          <>
            <h1 style={{paddingLeft: '1rem'}}>Utility program</h1>
            <div className="menu-flex-row-content" style={{marginBottom: '10vh', marginTop: '10vh'}}>
            {/*TODO SNIPPET*/}

              <div className="menu-item-content">
                <h2>Sale agents</h2>
                <div className="menu-flex-row">
                  <SalesAgentTbl listName={'sales_agent'} listHeaders={[{key: "Name", value: "name"}, {key: "Code", value: "code"}]} />
                </div>
              </div>
              <div className="menu-item-content">
                <h2>Regions</h2>
                <div className="menu-flex-row">
                  <RegionCodeTbl listName={'region_code'} listHeaders={[{key: "Region", value: "region"}, {key: "Code", value: "code"}]} />
                </div>
              </div>
              <div className="menu-item-content">
                <h2>Customer Types</h2>
                <div className="menu-flex-row">
                  <CustomerTypeTbl listName={'customer_type'} listHeaders={[{key: "Type", value: "customer_type"}, {key: "Code", value: "code"}]} />
                </div>
              </div>
              <div className="menu-item-content">
                <h2>Job Types</h2>
                <div className="menu-flex-row">
                  <CustomerTypeTbl listName={'job_type'} listHeaders={[{key: "Type", value: "job_type"}, {key: "Code", value: "code"}]} />
                </div>
              </div>
              <div className="menu-item-content">
                <h2>Field Tech</h2>
                <div className="menu-flex-row">
                  <FieldTechTbl />
                </div>
              </div>
              <div className="menu-item-content">
                <h2>Invoice items</h2>
                <div className="menu-flex-row">
                  <InvoiceItemTbl listName={'invoice_item'} listHeaders={[{key: "Description", value: "description"}, {key: "Code", value: "code"}]} />
                </div>
              </div>
            </div>
          </>
        ) : <h1 style={{margin: 'auto 1rem'}}>Access denied. Please login.</h1>}
        <Modal
               isOpen={this.state.showLoginModal}
               contentLabel={`Login modal`}
            >
          <button onClick={this.closeLoginModal}>X</button>
          <div className="login-container">
            <div className="login-form">
              <p className="login-title">Utility program Login</p>
              <div className="input-field">
                <input className="email-field" placeholder={'Email'} name={'email'} value={this.state.login.email} onChange={(event) => {this.updateLoginState(event)}} />
                <div className="error-message">
                  <p>{errorMessage.email}</p>
                </div>
              </div>
              <div className="input-field">
                <input className="password-field" type={inputType.password} placeholder={'Password'} name={'password'} value={this.state.login.password} onChange={(event) => {this.updateLoginState(event)}} onKeyPress={(e) => {return e.key == 'Enter' ? this.signIn() : null}} />
                <div className="error-message">
                  <p>{errorMessage.password}</p>
                </div>
              </div>
              <div className="show-password-field">
                <input type={'checkbox'} onChange={(event) => {this.setState({ showPass : !this.state.showPass})}}/>
                <p>Show password</p>
              </div>
              <button className="submit-button" onClick={() => this.signIn()}>login</button>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

export default App;

/*        
          <div className="menu-flex-row">
            <div className="menu-item-content">
              <div className="menu-flex-row-content" style={{width: '50%'}}>
                <p style={{width: '50%',margin: 'auto auto auto 1rem'}}>CUSTOMER:JOB</p>
                <input style={{width: '50%', margin: 'auto auto auto 1rem'}} />
              </div>
              <div className="menu-flex-row" style={{width: '100%'}}>
                <div className="menu-flex-row-content" style={{width: '50%'}}>
                  <h2>Invoice</h2>
                </div>
                <div className="menu-flex-row"  style={{width: '50%'}}>
                  <div className="menu-flex-row-content" style={{width: '50%'}}>
                    <div className="menu-flex-row-content" style={{width: '100%', marginBottom: '1rem'}}>
                      <p style={{margin: '4px'}}>DATE</p>
                      <input />
                    </div>
                    <div className="menu-flex-row-content" style={{width: '100%', marginBottom: '1rem'}}>
                      <p style={{margin: '4px'}}>INVOICE #</p>
                      <input />
                    </div>
                  </div>
                  <div className="menu-flex-row-content" style={{width: '50%'}}>
                    <div className="menu-flex-row-content" style={{width: '100%', marginBottom: '1rem', height: '100%'}}>
                      <p style={{margin: 'auto 1rem'}}>BILL TO</p>
                      <textarea style={{margin: 'auto 1rem', height: '100%'}}/>
                    </div>
                  </div>
                </div>
              </div>
              <div className="menu-flex-row" style={{width: '100%', justifyContent: 'right', marginBottom: '1rem'}}>
                <div className="menu-flex-row-content">
                  <p style={{margin: '4px'}}>REP</p>
                  <input />
                </div>
                <div className="menu-flex-row-content">
                  <p style={{margin: '4px'}}>REGION</p>
                  <input />
                </div>
                <div className="menu-flex-row-content">
                  <p style={{margin: '4px'}}>FIELD TECH</p>
                  <input />
                </div>
              </div>
              <div className="menu-flex-row">
                <InvoiceTbl listName={'invoice'} listHeaders={[{key: "Item", value: "item", type: "select", selectValue: [{"key": "ITM", "value": "ITM"}]},  {key: "Quantity", value: "quantity", type: "number"}, {key: "Description", value: "description", type: "string"},  {key: "Unit", value: "uom", type: "string"}, {key: "Rate", value: "rate", type: "number"}, {key: "Amount", value: "amount", type: "calculated", "calculation": { 'action': 'multiply', fields: ['rate', 'quantity'], type: 'dollar'}}]} />
              </div>
              <h2>Total: $</h2>
              //TODO get invoice total by `accounting_app/${this.state.listName}/${rowId}/${rowName}`
            </div>
            
            <div className="menu-item-content" style={{paddingBottom: '0px', paddingTop: '0px', background: '#aaa', color: 'white', width: '50%', height: '100%'}}>
              <div className="menu-flex-row-content" style={{width: '100%'}}>
                <h2>CUSTOMER:JOB</h2>
              </div>
              <div className="menu-flex-row-content">
                <div style={{paddingRight: '1rem'}}>
                  <button style={{borderBottom: 'unset', background: 'white', color: 'black'}}>Customer</button>
                  <button style={{borderBottom: 'unset', background: 'grey', color: 'white'}}>Transaction</button>
                </div>
                <div className="menu-flex-row-content" style={{background: 'white', color: 'black', width: '100%'}}>
                  <div style={{marginLeft: '1rem', marginRight: '1rem'}}>
                    <h4 style={{ borderBottom: '1px solid black'}}>SUMMARY</h4>
                    <div className="menu-flex-row" style={{width: '100%'}}>
                      <p style={{width: '50%'}}>Phone</p>
                      <p style={{width: '50%', textAlign: 'right'}}>123</p>
                    </div>
                    <div className="menu-flex-row" style={{width: '100%'}}>
                      <p style={{width: '50%'}}>Email</p>
                      <p style={{width: '50%', textAlign: 'right'}}>SERGIO@gmail.COM</p>
                    </div>
                    <div className="menu-flex-row" style={{width: '100%'}}>
                      <p style={{width: '50%'}}>Open balance</p>
                      <p style={{width: '50%', textAlign: 'right'}}>0.00</p>
                    </div>
                  </div>
                </div>
                <div className="menu-flex-row-content" style={{background: 'white', color: 'black', width: '100%'}}>
                  <div style={{marginLeft: '1rem', marginRight: '1rem'}}>
                    <h4 style={{ borderBottom: '1px solid black'}}>RECENT TRANSACTION</h4>
                    <div className="menu-flex-row" style={{width: '100%'}}>
                      <p style={{width: '50%'}}>10/27/22 Invoice - Paid</p>
                      <p style={{width: '50%', textAlign: 'right'}}>0.00</p>
                    </div>
                  </div>
                </div>
                <div className="menu-flex-row-content" style={{background: 'white', color: 'black', width: '100%'}}>
                  <div style={{marginLeft: '1rem', marginRight: '1rem'}}>
                    <h4 style={{ borderBottom: '1px solid black'}}>NOTES</h4>
                    <div className="menu-flex-row" style={{width: '100%'}}>
                      <p style={{width: '100%'}}>N/A</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
*/